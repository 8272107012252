<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_regul + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Регулятор мощности РМ3500</div>
          <div class="im-subtitle">
            Цифровая стабилизация и регулировка ±1 В
          </div>
          <div class="im-text">
            <p>
              Тарельчатая колонна DomSpirt, в отличие от насадочных колонн, не
              нуждается в стабилизации электропитания ТЭНа. Однако, если нужна
              регулировка (например хочется засыпать в колонну насадку, это
              возможно), либо уменьшать энергопотребление, Вам может
              потребоваться регулятор-стабилизатор мощности.
            </p>
            <p>
              Регулятор мощности с цифровой стабилизацией РМ-3500 позволяет
              регулировать напряжение и мощность любого электроприбора (в том
              числе ТЭНа для самогонного аппарата) мощностью до 3500 Вт с
              точностью до 1 В. Микропроцессор 100 раз в секунду отслеживает
              скачки напряжения и посылает управляющую комманду на симистор,
              обеспечивая высокоточную цифровую стабилизацию выставленной
              мощности. Кнопками можно управлять показаниями дисплея
              (вольты/ватты), а также выставлять нужную мощность. Общая длина
              кабеля 1 м.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_regul = require("@/pages/Main/assets/modal/m_regul.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_regul,
      car,
    };
  },
};
</script>

<style scoped></style>
